import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Transition} from 'react-transition-group';
import ComponentLoading from '../../../../ComponentLoading';
import TransactionTable from '../../tabComponents/Transactions/TransactionTable';
import LoadMoreButton from '../../../TransactionsPage/LoadMoreButton';
import TransactionHeaderDetails from '../../tabComponents/Transactions/TransactionHeaderDetails';
import {StyledTransactionsTabContainer, StyledTableContainer, StyledTable} from './StyledTransactionTab';
import TransactionDetailsPanel from '../../../TransactionsPage/TransactionDetailsPanel';
import TransactionDetails from '../../../TransactionsPage/TransactionDetails/TransactionDetails';
import AddInvoiceModal from '../../../TransactionsPage/AddInvoiceModal/AddInvoiceModal';
import {helpers} from '../../../../../helpers';
import {transactionsHelpers} from '../../../TransactionsPage/transactionsHelpers';
import {invoicesActions, transactionActions} from '../../../../../state/actions';

const duration = 300;
const tableElementId = 'transactions-table';

const {details: detailsStyles, table: tableStyles} = transactionsHelpers.getAnimationStyles({duration});

const TransactionsTab = ({
  addInvoiceModalProps,
  batchCreateInvoices,
  closeAddInvoiceModal,
  linkExpense,
  unlinkExpense,
  loading,
  loadMoreProps,
  transactions,
  employees,
  expenses,
  onExpenseUpdate,
  openAddInvoiceModal,
  subscription
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [storedTransactions, setStoredTransactions] = useState([]);
  const [storedExpenses, setStoredExpenses] = useState([]);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isFixedDetailsWindow, setIsFixedDetailsWindow] = useState(false);
  const [detailsWindowFixedHeight, setDetailsWindowFixedHeight] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(undefined);

  const tableNodeRef = useRef(null);
  const detailsNodeRef = useRef(null);

  useEffect(() => {
    let offsetTop = helpers.getElementOffsetTop(`#${tableElementId}`, 4);
    if (offsetTop !== detailsWindowFixedHeight) setDetailsWindowFixedHeight(offsetTop);
  }, [isFixedDetailsWindow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    transactionsHelpers.addFixedDetailsWindowScrollEventListener({
      detailsWindowFixedHeight, isFixedDetailsWindow, setIsFixedDetailsWindow
    });
  }, [isFixedDetailsWindow, detailsWindowFixedHeight]);

  const getGroupedTransactions = (transactions) => {
    const daysList = {};
    const datePropName = 'created_date';
    const summarizeKeyName = 'isYearlyGrouping';
    let groupedTransactions = [];
    let lastAdded;

    // define transactions months
    transactions.forEach(transaction => {
      const date = transaction[datePropName].slice(0, 4);
      const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
      let {amount, is_incoming: isIncoming} = transaction;
      amount = isIncoming ? amount : -amount;
      if (!isReturnedTransaction) {
        if (daysList.hasOwnProperty(date)) {
          daysList[date] = {
            amount: daysList[date].amount + amount
          }
        } else {
          daysList[date] = {amount}
        }
      }
    });

    // add grouping rows to transactions list
    transactions.forEach(t => {
      const date = t[datePropName].slice(0, 4);
      if (lastAdded !== date && daysList.hasOwnProperty(date)) {
        lastAdded = date;
        groupedTransactions.push({
          isYearlyGrouping: true,
          [datePropName]: date,
          ...daysList[date]
        });
      }
      groupedTransactions.push(t);
    });
    groupedTransactions = helpers.additionalTransactionsGrouping(groupedTransactions, summarizeKeyName);
    return groupedTransactions;
  }

  useEffect(() => {
    let storedTransactions = transactions.map(t => {
      const user = employees.find(e => e.employee_id === t.user_id);
      const expense = storedExpenses.find(e => e.id === t.expense_id);
      return {
        ...t,
        expense,
        user
      }
    });
    storedTransactions = getGroupedTransactions(storedTransactions);
    setStoredTransactions(storedTransactions);
  }, [employees, transactions, storedExpenses]);

  useEffect(() => setStoredExpenses(expenses), [expenses]);

  const handleOnExpenseUpdate = (updatedExpense, actionType) => {
    if (updatedExpense) {
      let updatedExpenses = helpers.getUpdatedBatchExpensesList(storedExpenses, updatedExpense, actionType);
      setSelectedTransaction({...selectedTransaction, expense: updatedExpense});
      setStoredExpenses(updatedExpenses);
      onExpenseUpdate && onExpenseUpdate(updatedExpense, actionType);
    }
  }

  const handleInvoiceRemove = (successCallback, errorCallback) => {
    transactionsHelpers.removeInvoiceFromTransaction({
      t,
      transaction: selectedTransaction,
      successCallback,
      errorCallback,
      unlinkExpense,
      onExpenseUpdate
    });
  }

  const handleOpenDetails = (transactionId, index) => {
    const transaction = storedTransactions.find(d => d.id === transactionId);
    setSelectedTransaction(transaction);
    setIsOpenDetails(true);
    selectedRowIndex !== index && setSelectedRowIndex(index);
  }

  const handleOkAttachmentModal = (formData, successCallback, errorCallback) => {
    transactionsHelpers.addInvoiceToTransaction({
      addInvoiceModalProps,
      formData,
      successCallback,
      errorCallback,
      batchCreateInvoices,
      closeAddInvoiceModal,
      linkExpense,
      onExpenseUpdate: handleOnExpenseUpdate
    });
  }

  const handleCloseDetails = () => {
    setIsOpenDetails(false);
    selectedRowIndex && setSelectedRowIndex(undefined);
  }

  return (
    <StyledTransactionsTabContainer>
      <TransactionHeaderDetails
        data={subscription}
        transactionsCount={transactions.length}
      />
      <StyledTableContainer>
        <Transition nodeRef={tableNodeRef} in={isOpenDetails} timeout={duration}>
          {state => (
            <StyledTable
              id={tableElementId}
              style={{
                ...tableStyles.default,
                ...tableStyles[state]
              }}
            >
              <TransactionTable
                totalSpend={subscription?.ytg || 0}
                data={storedTransactions}
                onExpenseUpdate={onExpenseUpdate}
                onRowClick={handleOpenDetails}
                onInvoiceEdit={openAddInvoiceModal}
                selectedRowIndex={selectedRowIndex}
              />

              <LoadMoreButton
                {...loadMoreProps}
              >
                {t('loadMore')}
              </LoadMoreButton>
            </StyledTable>
          )}
        </Transition>
        <Transition nodeRef={detailsNodeRef} in={isOpenDetails} timeout={duration}>
          {state => (
            <TransactionDetailsPanel
              onBack={handleCloseDetails}
              fixed={isFixedDetailsWindow}
              style={{
                ...detailsStyles.default,
                ...detailsStyles[state]
              }}
            >
              <TransactionDetails
                availableLinks={['card']}
                onExpenseUpdate={handleOnExpenseUpdate}
                onInvoiceEdit={openAddInvoiceModal}
                onInvoiceRemove={handleInvoiceRemove}
                subscription={subscription}
                transaction={selectedTransaction}
              />
            </TransactionDetailsPanel>
          )}
        </Transition>
      </StyledTableContainer>

      <AddInvoiceModal
        onCancel={closeAddInvoiceModal}
        open={addInvoiceModalProps.open}
        transaction={addInvoiceModalProps.transaction}
        onOk={handleOkAttachmentModal}
      />

      {loading && <ComponentLoading />}
    </StyledTransactionsTabContainer>
  );
}

TransactionsTab.propTypes = {
  expenses: PropTypes.array,
  loading: PropTypes.bool,
  loadMoreProps: PropTypes.object,
  onExpenseUpdate: PropTypes.func,
  subscription: PropTypes.object,
  transactions: PropTypes.array
}

TransactionsTab.defaultProps = {
  loading: false
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {addInvoiceModalProps} = state.transaction;

  return {
    addInvoiceModalProps,
    employees
  }
}

const mapDispatchToProps = {
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  closeAddInvoiceModal: transactionActions.closeAddInvoiceModal,
  linkExpense: invoicesActions.linkExpense,
  unlinkExpense: invoicesActions.unlinkExpense,
  openAddInvoiceModal: transactionActions.openAddInvoiceModal
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTab);
