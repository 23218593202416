import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {connect} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useLastLocation} from 'react-router-dom-last-location';
import {
  StyledCardsPageContainer,
  StyledCardsPageContent,
  StyledCardsPageTableRowPlaceholder
} from './StyledCardsPage';
import {
  StyledTableSettingsButtonDivider,
  StyledTableSettingsButtonSpace
} from '../../components/pages/SubscriptionsPage/TableSettingsButton/StyledTableSettingsButton';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import CardsTable from '../../components/pages/CardsPage/CardsTable';
import TableFilters from '../../components/pages/CardsPage/TableFilters';
import CostCenter from '../../components/pages/SubscriptionsPage/CostCenter';
import AddNewCardModal from '../../components/pages/CardsPage/AddNewCardModal';
import {
  bankingActions,
  cardActions,
  companyActions,
  mainActions,
  subscriptionActions,
} from '../../state/actions';
import UserDetails from '../../components/UserDetails';
import {helpers} from '../../helpers';
import {cardsHelpers} from '../../components/pages/CardsPage/cardsHelpers';
import {subscriptionsHelpers} from '../../components/pages/SubscriptionsPage/subscriptionsHelpers';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import CardPaymentType from '../../components/pages/CardsPage/CardPaymentType';
import EmptyCards from '../../components/pages/CardsPage/EmptyCards';
import WebSocket from '../../components/WebSocket';
import {tabHelpers} from '../../tabHelpers';
import {
  backendWebsocketActionsConstants,
  cardTypesConstants,
  SCAActionsConstants
} from '../../constants';
import {scaHelpers} from '../../scaHelpers';
import routes from '../../routes/routes.json';
import {useIsMount} from '../../hooks';
import {firebaseEvents} from '../../snippets/firebase';

const { CARD_CREATION_ACTION } = SCAActionsConstants;

const {FOR_PERSONAL_USE, FOR_SUBSCRIPTION} = cardTypesConstants;

const alwaysAvailableColumnKeys = ['payment', 'masked_pan', 'card_status', 'name'];

const defaultSelectedColumns = ['payment', 'masked_pan', 'card_status', 'name', 'owner', 'custom_limit',
  'expiry_date', 'amount', 'cost_centers'];

const perPagePagination = 25;

const defaultQuery = {
  per_page: perPagePagination,
}

const defaultTableData = {
  loaded: false,
  loadedPages: 1,
  pagination: null,
  page: 1,
  rows: []
}

const tableClassName = 'cards-table';

const getDate = (value) => value ? helpers.getTimestampDate(value, 'DD MMM YYYY') : '-';

const {logEvent} = helpers;

const CardsPage = ({
  createCard,
  createSubscription,
  getBankingCards,
  getCards,
  getEmployees,
  getTags,
  openLightKycWindow,
  storeCards,

  isAdmin,
  isRequiredCardVerification,
  storedCards,
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [loading, setLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState(cardsHelpers.getDefaultCardsTableColumns(defaultSelectedColumns));
  const [addCardModalProps, setAddCardModalProps] = useState({loading: false, open: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [query, setQuery] = useState(defaultQuery);
  const [tableData, setTableData] = useState(defaultTableData);
  const [activeAuthAction, setActiveAuthAction] = useState(null);
  const [tempData, setTempData] = useState({
    cardType: undefined,
    data: undefined,
    errorCallback: undefined,
    successCallback: undefined
  });
  const [websocketWaitCard, setWebsocketWaitCard] = useState(undefined);
  const location = useLocation();
  const {lastLocation} = useLastLocation();
  const isMounted = !useIsMount();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // enable websocket connection only when some cards in wait status
  const enabledWebSocketConnection = useMemo(() => websocketWaitCard !== undefined, [websocketWaitCard]);

  const trans = (key) => t(`cards:${key}`);

  const loadCards = ({initial = true, requestQuery} = {}) => {
    const latestQuery = {...query, ...requestQuery};
    setLoading(true);
    setQuery(latestQuery);
    getCards(
      helpers.getSearchParams(latestQuery),
      (response) => {
        const updatedTableData = tabHelpers.getUpdatedTableData({initial, response, tableData});
        setTableData(updatedTableData);
        setLoading(false);
        storeCards({
          tableData: updatedTableData,
          query: latestQuery
        });
      },
      () => setLoading(false)
    );
  }

  useEffect(() => {
    if (lastLocation && lastLocation.pathname === location.pathname) return;
    if (!isMounted && lastLocation === null) return;

    const searchParamsQuery = Object.fromEntries(searchParams.entries());
    const initialLoadCardsParams = {initial: true, requestQuery: searchParamsQuery};

    if (lastLocation) {
      let detailsPath = routes.cardDetails;
      let detailsPreIdRoute = detailsPath.slice(0, detailsPath.indexOf('/', detailsPath.indexOf('/') + 1));
      setLoading(true);
      try {
        if (lastLocation.pathname.startsWith(detailsPreIdRoute) && storedCards) {
          const query = storedCards.query;
          const tableData = storedCards.tableData;
          setTableData(tableData);
          setQuery({...query, ...searchParamsQuery});
          getCards(
            helpers.getSearchParams(storedCards.query),
            (response) => {
              const data = response.results || [];
              const page = tableData.page;
              const updatedTableData = {
                loaded: true,
                loadedPages: page,
                pagination: response?.pagination || null,
                page,
                rows: page === 1 ? data : [...tableData.rows.slice(0, (page - 1) * perPagePagination), ...data]
              };
              setTableData(updatedTableData);
              setLoading(false);
              storeCards({ tableData: updatedTableData, query });
            },
            () => setLoading(false)
          );
        } else {
          loadCards(initialLoadCardsParams);
        }
      } catch (e) {
        loadCards(initialLoadCardsParams);
      }
    } else {
      loadCards(initialLoadCardsParams);
    }
  }, [lastLocation, isMounted]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getBankingCards();
    getTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isAdmin && getEmployees();
  }, [isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      dataIndex: 'masked_pan',
      title: t('payment'),
      render: (value, record) => <CardPaymentType data={record} />
    },
    {
      dataIndex: 'masked_pan',
      title: trans('last4Digits'),
      render: cardsHelpers.getLast4Digits
    },
    {
      dataIndex: 'card_status',
      title: trans('cardStatus'),
      render: (_, record) => !record.placeholder && cardsHelpers.getCardStatusMark(record)
    },
    {
      dataIndex: 'name',
      title: trans('cardName'),
      render: (value, record) => record.placeholder ? (
          <StyledCardsPageTableRowPlaceholder>
            {trans('creatingNewCard')}, {t('loading')}...
          </StyledCardsPageTableRowPlaceholder>
        ) : value
    },
    isAdmin && {
      dataIndex: 'owner',
      title: trans('cardOwner'),
      render: (owner) => owner && <UserDetails user={owner} />
    },
    {
      align: 'right',
      dataIndex: 'custom_limit',
      title: t('Budget'),
      render: (value, record) => {
        if (value) {
          return (
            <span className='amount'>
              {helpers.getMoneyView(value)} {t(subscriptionsHelpers.getCardLimitType(record?.limit_period))}
            </span>
          );
        }
      }
    },
    {
      dataIndex: 'expiry_date',
      title: trans('expiryDate'),
      render: (value) => value && helpers.getDate(value, 'MM/YY')
    },
    {
      align: 'right',
      dataIndex: 'amount',
      title: trans('totalAmountMonth'),
      render: (value) => {
        if (value !== undefined) {
          let className = 'amount';
          if (value) className += ' danger-text';
          return (
            <span className={className}>
              {helpers.getMoneyView(value ? value : -value)}
            </span>
          )
        }
      }
    },
    {
      dataIndex: 'created_date',
      title: t('createdDate'),
      render: getDate
    },
    {
      dataIndex: 'last_transaction_date',
      title: t('lastTransactionDate'),
      render: getDate
    },
    {
      dataIndex: 'cost_centers',
      title: t('costCentre'),
      width: 190,
      render: (tags) => <CostCenter tags={tags} />,
    },
  ];

  const onChangeSelectedItems = (value) => {
    setSelectedColumns(value);
    cardsHelpers.storeCardsTableColumns(value);
  }

  const handleOnPaginate = (activePage) => {
    const {page: currentPage, loadedPages} = tableData;
    if (activePage > currentPage && loadedPages < activePage) {
      let requestQuery = {...query};
      if (tableData.pagination && tableData.pagination.cursor) {
        requestQuery = {...requestQuery, cursor: tableData.pagination.cursor};
      }
      loadCards({initial: false, requestQuery});
    } else {
      setTableData({
        ...tableData,
        page: activePage
      });
    }
  }

  const onFilter = (filterProps) => {
    loadCards({requestQuery: { ...filterProps, cursor: null}});
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      ...filterProps
    });
    logEvent(firebaseEvents.CARDS_USE_CARDS_TABLE_FILTER);
  }

  const onRowClick = (e, record) => {
    const cardId = record?.card_id;
    const userId = record?.owner?.id;
    const placeholder = record?.placeholder || false;
    if (e.target.closest(`.${tableClassName}`) && cardId && userId && !placeholder) navigate(`${routes.cardsList}/${userId}/${cardId}`);
  }

  const handleCancelAddCardModal = () => setAddCardModalProps({...addCardModalProps, open: false});

  const handleOpenAddCardModal = () => setAddCardModalProps({...addCardModalProps, open: true});

  const handleFinishCardCreation = (data) => {
    setActiveAuthAction(CARD_CREATION_ACTION);
    setTempData(data);
    handleCreateCard({action: CARD_CREATION_ACTION, data});
  }

  const handleCreateCard = ({action, data} = {}) => {
    if (data === undefined) data = tempData;

    const cardType = data?.cardType;
    const formData = data?.data;
    const errorCallback = data?.errorCallback;
    const successCallback = data?.successCallback;
    const creationFunctions = {
      [FOR_PERSONAL_USE]: createCard,
      [FOR_SUBSCRIPTION]: createSubscription,
    };
    const creationLogEvents = {
      [FOR_PERSONAL_USE]: firebaseEvents.CARDS_CREATE_NEW_PERSONAL_CARD,
      [FOR_SUBSCRIPTION]: firebaseEvents.CARDS_CREATE_NEW_SUBSCRIPTION_CARD,
    }
    const creationFunction = creationFunctions[cardType];
    const creationLogEvent = creationLogEvents[cardType];
    if (creationFunction) {
      setAddCardModalProps({...addCardModalProps, loading: true});
      creationFunction({
        headers: scaHelpers.getAuthHeaders(action || activeAuthAction),
        data: formData,
        successCallback: (responseData) => {
          const placeholderRow = {
            ...responseData,
            subscription_id: cardType === FOR_SUBSCRIPTION ? responseData.id : undefined,
            placeholder: true
          };
          successCallback && successCallback(responseData);
          setAddCardModalProps({...addCardModalProps, loading: false, open: false});
          setWebsocketWaitCard(responseData);
          setTableData({
            ...tableData,
            rows: [placeholderRow, ...tableData.rows]
          });
          creationLogEvent && logEvent(creationLogEvent);
        },
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
            errorCallback: (response) => {
              setAddCardModalProps({...addCardModalProps, loading: false});
              errorCallback && errorCallback(response);
            }
          });
        }
      })
    }
  }

  const handleCancelAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    addCardModalProps.loading && setAddCardModalProps({...addCardModalProps, loading: false});
  }

  const handleOnSuccessAuthModal = () => {
    const actions = {
      [CARD_CREATION_ACTION]: handleCreateCard
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (actions.hasOwnProperty(activeAuthAction)) actions[activeAuthAction]();
  }

  const handleWebsocketMessage = (data) => {
    const gRespProp = (key) => helpers.getObjProp(data, key);

    const action = gRespProp('action');
    const cardTempId = gRespProp('temp_card_id');
    // update subscription after receiving card details
    if ([backendWebsocketActionsConstants.CARD_CREATE].includes(action)) {
      if (websocketWaitCard) {
        if (cardTempId) {
          const card = {
            ...websocketWaitCard,
            card_id: gRespProp('card_id'),
            expiry_date: gRespProp('expiry_date'),
            masked_pan: gRespProp('card_masked_pan'),
            placeholder: false
          }
          setTableData({
            ...tableData,
            rows: tableData.rows.map(c => c.card_id === cardTempId ? card: c)
          });
        } else {
          loadCards(true);
        }
        setWebsocketWaitCard(undefined);
      }
    }
  }

  const availableColumns = useMemo(() => helpers.getAvailableTableColumns({alwaysAvailableColumnKeys, columns, selectedColumns}), [isAdmin, selectedColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterFields = useMemo(() => {
    let fields = ['cost_centers'];
    if (isAdmin) fields = [...fields, 'assigned_to', 'card_numbers', 'card_owners'];
    return fields;
  }, [isAdmin]);

  const isEmptyCardsList = useMemo(() => {
    return !loading && tableData.rows.length === 0 && Object.keys(query).filter(i => i !== 'per_page').length === 0;
  }, [loading, tableData, query]);

  const getRightSideContent = () => {
    const settingsIcon = helpers.getSettingsRightSideContent({
      alwaysAvailableColumnKeys,
      columns,
      rightSideContent: null,
      onChange: onChangeSelectedItems,
      selectedKeys: selectedColumns
    });
    return (
      <StyledTableSettingsButtonSpace size={0} split={<StyledTableSettingsButtonDivider type='vertical' />}>
        {settingsIcon}
        {isAdmin && (
          <Button
            onClick={handleOpenAddCardModal}
            size='large'
            type='primary'
          >
            {t('add')} {t('new')}
          </Button>
        )}
      </StyledTableSettingsButtonSpace>
    );
  }

  const getTableProps = () => {
    const rowsLength = tableData.rows.length;
    const total = tableData?.pagination?.cursor ? rowsLength + 1 : rowsLength;
    const pagination = {
      current: tableData.page || 1,
      disabled: !Boolean(rowsLength),
      onChange: handleOnPaginate,
      pageSize: perPagePagination,
      showSizeChanger: false,
      total
    }
    return {
      className: tableClassName,
      columns: availableColumns,
      data: tableData.rows,
      emptyMessage: loading ? ' ' : undefined,
      loading,
      onRowClick,
      pagination
    }
  }

  return (
    <>
      <PageDocumentDetails title={t('pageTitles.cardsList')} />
      <BalancePageHeader breadcrumbs={[{title: t('cards')}]} />
      <StyledCardsPageContainer>
        {(isEmptyCardsList || isRequiredCardVerification) ? (
          <EmptyCards
            className='cards-page-empty'
            enableButton={isAdmin}
            onClick={isRequiredCardVerification ? openLightKycWindow : handleOpenAddCardModal}
            variant={isRequiredCardVerification ? 'banking' : 'empty'}
          />
        ) : (
          <StyledCardsPageContent size='large' direction='vertical'>
            <TableFilters
              defaultQuery={query}
              filterFields={filterFields}
              onFilter={onFilter}
              rightSideContent={getRightSideContent()}
            />
            <CardsTable {...getTableProps()} />
          </StyledCardsPageContent>
        )}

        <AddNewCardModal
          {...addCardModalProps}
          onCancel={handleCancelAddCardModal}
          onFinish={handleFinishCardCreation}
        />

        <AuthenticationWindow
          {...authWindowProps}
          handleCancel={handleCancelAuthModal}
          onSuccess={handleOnSuccessAuthModal}
          operationName={activeAuthAction}
        />

        {enabledWebSocketConnection && <WebSocket onMessage={handleWebsocketMessage} />}

      </StyledCardsPageContainer>
    </>
  );
}

const mapStateToProps = state => {
  const {isAdmin} = state.user;
  const {storedCards} = state.card;
  const isRequiredCardVerification = cardsHelpers.checkIsRequiredVerification(state.banking.verification);

  return {
    isAdmin,
    isRequiredCardVerification,
    storedCards,
  }
}

const mapDispatchToProps = {
  createCard: cardActions.createCard,
  createSubscription: subscriptionActions.createSubscription,
  getBankingCards: bankingActions.getCardsList,
  getCards: cardActions.getCards,
  getEmployees: companyActions.getEmployees,
  getTags: companyActions.getTags,
  openLightKycWindow: mainActions.openLightKycWindow,
  storeCards: cardActions.storeCards,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsPage);
